import { useState, useEffect } from 'react';
import BottomBar from './BottomBar';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Accordion, Button, Form, Card } from 'react-bootstrap';
import ReactSnackBar from 'react-js-snackbar';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import { extractLemmas, annotateEngDic } from './engDicAnnotator';
import ReactGA from 'react-ga';

function App() {
    //    ReactGA.initialize('UA-185250602-4');
    //    ReactGA.pageview(window.location.pathname + window.location.search);

    const [wordDifficultyDegree, setWordDifficultyDegree] = useState(20);
    const [input, setInput] = useState(
        'He is trying to fish for fish in the lake.\n\nThe NASA Perseverance rover safely landed on Mars after its 292.5 million-mile journey from Earth, the agency confirmed at 3:55 p.m. ET Thursday. The rover landed itself flawlessly, according to the mission\'s team.\n\n"Percy," as the spacecraft is affectionately called at mission control, sent back its first images of the landing site immediately after touchdown, which shows the rover\'s shadow on the surface of its landing site of Jezero Crater.'
    );
    const [output, setOutput] = useState('');
    const [meaningByPos, setMeaningByPos] = useState({});
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [snackbarShowing, setSnackbarShowing] = useState(false);

    const [htmlOutput, textOutput] = annotateEngDic(output, meaningByPos);
    const annotatedOutputInHtml = ReactHtmlParser(
        htmlOutput.replace(/\n/g, '<br>')
    );

    useEffect(() => {
        convertText();
    }, [wordDifficultyDegree]);

    const handleInputChange = (e) => {
        e.preventDefault();
        setInput(e.target.value);
    };
    const handleChangeForWordDifficultyDegree = (e) => {
        setWordDifficultyDegree(e.target.value);
    };
    const fetchWordsInfo = async (lemmas) => {
        const url = 'https://annodic.hanmesoft.com/dic_server/getWordsInfo.php';
        // The following is to fix the post params not being set as $_POST in php code.
        // Reference: https://github.com/axios/axios/issues/1195.
        const params = new URLSearchParams();
        params.append('words', JSON.stringify(lemmas));
        params.append('rankCutoff', wordDifficultyDegree * 50);

        const res = await axios.post(url, params);
        const data = res.data;
        //console.log(data);
        setMeaningByPos(data.wordsInfo);
    };
    const clearText = (e) => {
        setInput('');
        setOutput('');
        setMeaningByPos({});
    };
    const convertText = (e) => {
        setOutput(input);
        const lemmas = extractLemmas(input);
        fetchWordsInfo(lemmas);
    };
    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        document.body.appendChild(textArea);
        textArea.value = text;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };
    const showSnackbar = () => {
        if (snackbarShowing) {
            return;
        }
        setSnackbarShowing(true);
        setSnackbarShow(true);
        setTimeout(() => {
            setSnackbarShow(false);
        }, 2000);
        setTimeout(() => {
            setSnackbarShowing(false);
        }, 3600);
    };
    const copyOutputToClipboard = (e) => {
        copyToClipboard(textOutput);
        showSnackbar();
    };

    return (
        <>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-12 m-0 pb-0">
                        <h1 className="display-4 my-2">
                            <a
                                href="https://annodic.hanmesoft.com"
                                className="text-decoration-none"
                            >
                                주석 달아주는 사전
                            </a>
                        </h1>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-6 col-xl-4 m-0 py-0 px-3">
                        <textarea
                            className="form-control my-2"
                            placeholder="영어 문장들을 여기에 입력하고 '변환하기' 버튼을 누르세요."
                            rows="8"
                            onChange={handleInputChange}
                            value={input}
                        />
                        <Button
                            variant="primary"
                            className="m-2"
                            onClick={convertText}
                            disabled={input === ''}
                            style={{
                                minWidth: '266px',
                                minHeight: '60px',
                            }}
                        >
                            변환하기
                        </Button>
                        <Accordion>
                            <Button
                                variant="info"
                                className="m-2"
                                onClick={clearText}
                                disabled={input === ''}
                            >
                                입력창 지우기
                            </Button>
                            <Accordion.Toggle
                                as={Button}
                                variant="info"
                                className="m-2"
                                eventKey="0"
                            >
                                난이도 조절
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card bg="light" className="m-2 my-0">
                                    <Card.Body>
                                        <Form>
                                            <Form.Group
                                                controlId="formRange1"
                                                className="m-0 p-0"
                                            >
                                                <Form.Label className="m-0 p-0 mb-2">
                                                    자주 쓰이는{' '}
                                                    {Number(
                                                        wordDifficultyDegree *
                                                            50
                                                    ).toLocaleString()}{' '}
                                                    단어 배제
                                                </Form.Label>
                                                <Form.Control
                                                    type="range"
                                                    step="5"
                                                    value={wordDifficultyDegree}
                                                    onChange={
                                                        handleChangeForWordDifficultyDegree
                                                    }
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </Accordion>
                        {output !== '' && (
                            <>
                                <h5 className="mt-2 mb-0">주석달린 문장:</h5>
                                <Card
                                    bg="light"
                                    className="mx-0 my-2 pl-1 text-left"
                                >
                                    <Card.Body className="p-2">
                                        {annotatedOutputInHtml}
                                    </Card.Body>
                                </Card>
                                <Button
                                    variant="info"
                                    className="m-2"
                                    onClick={copyOutputToClipboard}
                                    style={{
                                        minWidth: '266px',
                                    }}
                                >
                                    클립보드에 복사
                                </Button>
                            </>
                        )}
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                </div>
            </div>
            <ReactSnackBar Icon={<span>📝</span>} Show={snackbarShow}>
                <div className="text-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;변환된 텍스트가
                    복사되었습니다.
                </div>
            </ReactSnackBar>
            <BottomBar />
        </>
    );
}

export default App;
