import posTagger from 'wink-pos-tagger';

const tagger = posTagger();

const getLemma = (tagItem) => {
    return tagItem.hasOwnProperty('lemma') ? tagItem.lemma : tagItem.normal;
};

const getLemmas = (tagged) => {
    const words = {};
    for (let tagItem of tagged) {
        const word = getLemma(tagItem);
        words[word] = 1;
    }
    return Object.keys(words);
};

const getMeaningByPos = (tagItem, meaningByPos) => {
    const word = getLemma(tagItem);
    if (!meaningByPos.hasOwnProperty(word)) {
        return null;
    }
    let matchingDicPoses = [];
    //    return tagItem.pos;
    // TODO: why 'v.'?
    // TODO: NN followed by another NN should be interpreted as adj. first.
    // TODO: distinguish vt. from vi.
    // TODO: composite words.   용어사전?
    switch (tagItem.pos) {
        // From https://cs.nyu.edu/grishman/jet/guide/PennPOS.html.
        case 'JJ': // Adjective
        case 'JJR': // Adjective, comparative
        case 'JJS': // Adjective, superlative
            matchingDicPoses = ['adj', '*'];
            break;

        case 'NN': // Noun, singular or mass
        case 'NNS': // Noun, plural
        case 'NNP': // Proper noun, singular
        case 'NNPS': // Proper noun, plural
            matchingDicPoses = ['n', '*'];
            break;

        case 'RB': // Adverb
        case 'RBR': // Adverb, comparative
        case 'RBS': // Adverb, superlative
            matchingDicPoses = ['adv', '*'];
            break;

        case 'VB': // Verb, base form
        case 'VBD': // Verb, past tense
        case 'VBG': // Verb, gerund or present participle
        case 'VBN': // Verb, past participle
        case 'VBP': // Verb, non-3rd person singular present
        case 'VBZ': // Verb, 3rd person singular present
            matchingDicPoses = ['vt', 'vi', 'v', '*'];
            break;

        case 'CC': // Coordinating conjunction
        case 'CD': // Cardinal number
        case 'DT': // Determiner
        case 'EX': // Existential there
        case 'FW': // Foreign word
        case 'IN': // Preposition or subordinating conjunction

        case 'LS': // List item marker
        case 'MD': // Modal

        case 'PDT': // Predeterminer
        case 'POS': // Possessive ending
        case 'PRP': // Personal pronoun
        case 'PRP$': // Possessive pronoun

        case 'RP': // Particle
        case 'SYM': // Symbol
        case 'TO': // to
        case 'UH': // Interjection

        case 'WDT': // Wh-determiner
        case 'WP': // Wh-pronoun
        case 'WP$': // Possessive wh-pronoun
        case 'WRB': // Wh-adverb
        default:
            return null;
    }
    const wordInfo = meaningByPos[word];
    for (let matchingDicPos of matchingDicPoses) {
        if (wordInfo.hasOwnProperty(matchingDicPos)) {
            const meaning = wordInfo[matchingDicPos];
            return meaning;
        }
    }
    return null;

    //    let meaning = tagItem.pos;
    //    for (let dicPos in wordInfo) {
    //        meaning = wordInfo[dicPos];
    //        break;
    //    }
    //    return meaning;
};

export const extractLemmas = (text) => {
    const tagged = tagger.tagSentence(text);
    const lemmas = getLemmas(tagged);
    return lemmas;
};

export const annotateEngDic = (text, meaningByPos) => {
    const htmlOutputs = [];
    const textOutputs = [];

    const tagged = tagger.tagSentence(text);
    console.log(tagged);

    let currIndex = 0;
    for (let tagItem of tagged) {
        const orgWord = tagItem.value;
        const orgWordIndex = text.indexOf(orgWord, currIndex);
        //        console.log(orgWordIndex, orgWord);
        htmlOutputs.push(text.substring(currIndex, orgWordIndex));
        textOutputs.push(text.substring(currIndex, orgWordIndex));
        //        htmlOutputs.push(orgWord);
        const meaning = getMeaningByPos(tagItem, meaningByPos);
        if (meaning !== null) {
            htmlOutputs.push(
                `<span class="my-tooltip">${orgWord}</br><span class="my-tooltiptext unselectable">${meaning}</span></span>`
            );
            textOutputs.push(`${orgWord}(${meaning})`);
        } else {
            htmlOutputs.push(
                `<span class="my-tooltip">${orgWord}</br>&nbsp;</span>`
            );
            textOutputs.push(orgWord);
        }
        currIndex = orgWordIndex + orgWord.length;
    }
    htmlOutputs.push(text.substring(currIndex));
    textOutputs.push(text.substring(currIndex));

    if (false) {
        for (let c of text.split('')) {
            if (true) {
                htmlOutputs.push(
                    `<span class="my-tooltip">${c}<span class="my-tooltiptext unselectable">${c}</span></span>`
                );
            } else {
                htmlOutputs.push(c);
            }
        }
    }
    return [htmlOutputs.join(''), textOutputs.join('')];
};
